<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="状态" class="col-auto">
            <el-select style="width: 100px" v-model="params.status" size="small" placeholder="请选择" clearable>
              <el-option label="全部" value="" />
              <el-option label="启用中" :value="1" />
              <el-option label="禁用中" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <el-input style="width:220px" clearable v-model="params.name" placeholder="请输入活动名称进行搜索"></el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
            <el-button @click="addNotice()" type="primary" size="small">添加活动</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="活动名称">
          <template slot-scope="{row}">{{ row.name }}</template>
        </el-table-column>

        <el-table-column label="活动时间" width="320">
          <template slot-scope="{row}">{{ row.starting_time | unixToDate('yyyy-MM-dd hh:mm') }} ~ {{ row.ending_time |
            unixToDate('yyyy-MM-dd hh:mm')
          }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{row}">
            <span>{{ row.status ? '启用中' : '禁用中' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot-scope="{row}">
            <el-button v-if="row.status" size="mini" type="danger" @click="editNotice(row, 'disabled')">禁用</el-button>
            <el-button v-if="!row.status" size="mini" type="primary" @click="editNotice(row, 'open')">启用</el-button>
            <el-button v-if="!row.status" size="mini" type="primary" @click="editNotice(row, 'edit')">编辑</el-button>
            <el-button v-if="!row.status" size="mini" type="danger" @click="deletez(row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { pieceActivityList, deletePieceActivity, updatePieceActivity } from '@/api/shopSetting';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
export default {
  name: 'firstPieceList',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        enable_status: ''
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  filters: {

  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**新增 */
    addNotice () {
      this.$router.push({ path: "/tools/promotion-manage/firstPieceEdit" });
    },
    /**打开弹窗 */
    async editNotice (row, type) {
      switch (type) {//获取地址
        case "edit"://编辑
          this.$router.push({ path: "/tools/promotion-manage/firstPieceEdit", query: { id: row.id } });
          break;
        case "open"://启用  
          this.$confirm("确定启用该活动吗?", "提示", { type: "warning" }).then(() => {
            updatePieceActivity({ id: row.id, status: 1 }).then(res => {
              this.$message.success("启用成功");
              this.GET_AccountList();
            });
          });
          break;
        case "disabled": //禁用 
          this.$confirm("确定禁用该活动吗?", "提示", { type: "warning" }).then(() => {
            updatePieceActivity({ id: row.id, status: 0 }).then(res => {
              this.$message.success("禁用成功");
              this.GET_AccountList();
            });
          });
          break;
        default:

          break;
      }

    },
    deletez (row) {//删除
      this.$confirm("确定删除该活动吗?", "提示", { type: "warning" }).then(() => {
        deletePieceActivity(row.id).then(res => {
          this.$message.success("删除成功");
          this.GET_AccountList();
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      pieceActivityList(params).then(response => {
        this.loading = false;
        this.tableData = response.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
